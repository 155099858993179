$lightShade: #EEEDE6;
$lightAccent: #9D9782;
$mainBrand: #7D67B2;
$darkAccent: #6487CA;
$darkShade: #362E51;
$darkShadeContrast: #211d2f;

$primary: $mainBrand;
$info: $darkShade;
$success: #5b996d;
$warning: #d88935;
$danger: #f44336;

$theme-colors: (
  "primary": $mainBrand,
  "info": $darkShade,
  "success": $success,
  "warning": $warning,
  "danger": $danger
);

@import "~bootstrap/scss/bootstrap";

body {
  background-color: $darkShade;
  color: $lightShade;
  font-size: 14px;
}

.navbar-dark {
  background-color: $mainBrand !important;

  .navbar-brand {
    color: $lightShade;
  }
}

.container {
  padding: 15px;
}

.conHeader {
  position: relative;
  margin: 0 0 20px 0;
  padding: 0;
  max-height: 225px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 5px 15px 0px;

  .show-desktop {
    position: absolute;
    bottom: 0px;
    margin-left: 15px;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 10px;
    width: 450px;
    border-radius: 10px 10px 0px 0px;
  }
}

.roomAvailability {
  text-align: center;

  th {
    padding: 0 !important;
    border: 0 !important;
  }
}

.main-container {
  height: 100%;

  .row {
    margin: 0 !important;
  }
}

.card {
  background-color: $lightShade;
  color: $darkShade;
  margin-bottom: 10px;
}

.hotel-banner {
  border-radius: 4px;
  max-height: 220px;
  overflow: hidden;

  img {
    width: 100%;
    margin-top: -30px;
  }
}

.hotel-info {
  img {
    max-width: 75px;
    float: left;
    margin: 10px 15px 10px 10px;
    border-radius: 3px;
  }
}

.badge {
  cursor: default;
}

.bg-warning, .badge-warning {
  color: $darkShadeContrast;
}

.important-banner {
  color: $darkShadeContrast;
  background-color: #EF7C01;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.pull-right {
  float: right;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.padding-0 {
  padding: 0 !important;
}

.card-body {
  padding: 0.75rem;
}

hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.btn.disabled {
  cursor: not-allowed;
}

.avatar {
  margin-bottom: 5px;

  img {
    border-radius: 50%;
    border: 1px solid $darkShadeContrast;
  }
}

.react-calendar {
  color: $darkShade;
  background-color: $lightShade;
  border-radius: 0.25rem;
  margin-bottom: 10px;
  width: 100%;

  .react-calendar__month-view__days__day {
    color: $darkShade !important;
  }

  .react-calendar__tile--active {
    color: $lightShade !important;
  }

  :disabled {
    background-color: #dedcdc;
  }

  .react-calendar__tile--rangeStart {
    background-image: -webkit-linear-gradient(135deg, #006edc 50%, $darkAccent 50%);
  }

  .react-calendar__tile--rangeEnd {
    background-image: -webkit-linear-gradient(135deg, $darkAccent 50%, #006edc 50%);
  }
}

.room-type {
  p {
    margin-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
  }

  .icons {
    margin-bottom: 0.75rem;
  }

  .availability {
    width: 50px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;

    background-color: #f443364d;
    border: 1px solid #f44336;
  }

  .availability.available {
    background-color: #5b996d4d;
    border: 1px solid #5b996d;
  }
}

.material-icons, .unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.material-icons.medium {
  font-size: 32px;
}

.material-icons.large {
  font-size: 64px;
}

.material-icons.inline {
  position: relative;
  top: 6px;
  margin-right: 4px;
}

.markdownPlain * {
  margin-bottom: 0 !important;
}

@media (max-width: 480px) {
  .show-desktop {
    display: none;
  }
}

@media (min-width: 576px) {
  .show-mobile {
    display: none;
  }
}
